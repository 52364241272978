import * as React     from 'react';
import { withRouter } from 'react-router';
import { connect }    from 'react-redux';
import classnames     from 'classnames';
import { Link }       from 'react-router-dom';
import Cookies        from 'js-cookie';
import style          from './style';
import * as propTypes from '../../../propTypes';

import Header                          from '../../components/Header';
import Button                          from '../../components/Button';
// import Search from '../../components/Search';
import Icon                            from '../../../components/Icon';
import CitySelect                      from '../CitySelect';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';


class Menu extends React.PureComponent {
  static propTypes = {
    cart:    propTypes.cart,
    city:    propTypes.city,
    history: propTypes.history,
  };

  state = {
    showCitySelect: false,
  };

  componentDidMount() {
    this.historyListener = this.props.history.listen(() => {
      this.toggler.checked = false;
    });
  }

  componentWillUnmount() {
    if (this.historyListener) this.historyListener();
  }

  toDesktop = () => {
    const { ID, DOMAIN } = this.props.config;
    const expires = new Date();
    expires.setYear(expires.getFullYear() + 1);
    Cookies.set(`env-${ID}`, 'desktop', { domain: DOMAIN, expires });
    window.location.reload();
  };

  onItemClick( e, item ) {
    e.preventDefault();
    if (item.link) {
      this.toggler.checked = false;
      this.props.history.push(item.link);
    }
    if (item.action) {
      item.action();
    }
  }

  showCitySelect = () => this.setState({ showCitySelect: true });
  hideCitySelect = () => this.setState({ showCitySelect: false });

  renderLeftButton() {
    if (!this.state.showCitySelect) return null;
    return <Button onClick={this.hideCitySelect} filled icon="arrow_left" size="sm" />;
  }

  renderCloseButton() {
    return (
      <label htmlFor="menu">
        <Button tag="block" onClick={this.hideCitySelect} filled icon="close" size="sm" />
      </label>
    );
  }

  renderItem = ( item, i ) => {
    let Tag;
    const TagProps = {
      key:       i,
      className: style.item,
      onClick:   e => this.onItemClick(e, item),
    };
    if (item.link) {
      Tag = Link;
      TagProps.to = item.link;
    } else {
      Tag = 'div';
    }
    return (
      <Tag {...TagProps}>
        {item.icon && <div className={style.icon_wrapper}><Icon glyph={item.icon} className={style.icon} /></div>}
        <div className={classnames(style.text, !item.icon && style.text_padding)}>{item.text}</div>
      </Tag>
    );
  };

  render() {
    const menuVisible = this.props.config.MENU_HIDDEN !== true;
    const total = this.props.cart.cart.reduce(( t, item ) => t + +item.quantity, 0);
    // const city = this.props.city && this.props.city.title || 'Не выбран';
    const items = [
      // { icon: 'profile', text: 'Личный кабинет', link: '/cabinet' },
      // { icon: 'geo', text: `Город: ${city}`, action: this.showCitySelect },
      { icon: 'cart', text: `Корзина (${total})`, link: '/cart' },
      menuVisible && { icon: 'search', text: 'Что с моим заказом?', link: '/check_order' },
    ].filter(i => i);
    const itemsSub = [
      menuVisible && { text: 'Главная страница', link: '/' },
      menuVisible && { text: 'О компании', link: '/page/about' },
      menuVisible && { text: 'Доставка', link: '/page/delivery' },
      menuVisible && { text: 'Как купить?', link: '/page/how' },
      menuVisible && { text: 'Франшиза', link: '/page/franchise' },
      menuVisible && { text: 'Контактная информация', link: '/page/contacts' },
      menuVisible && { text: 'Возврат и гарантия', link: '/page/guarantee' },
      menuVisible && { text: 'Политика конфиденциальности', link: this.props.config.POLICY },
      menuVisible && { text: 'Пользовательское соглашение', link: this.props.config.TERMS },
      menuVisible && { text: 'Новости', link: `/news` },
      { text: 'Полная версия сайта', action: this.toDesktop },
    ].filter(i => i);

    return (
      <div>
        <input type="checkbox" id="menu" className={style.toggler} ref={( c ) => { this.toggler = c; }} />
        <div className={style.wrapper}>
          <Header
            left={this.renderLeftButton()}
            center={this.state.showCitySelect ? 'Выбор города' : 'Меню'}
            right={this.renderCloseButton()}
          />
          <div className={classnames(style.menu, this.state.showCitySelect && style.citySelect)}>
            <div className={style.content}>
              {/* <Search /> */}
              <div className={style.items}>
                {items.map(this.renderItem)}
              </div>
              <div className={style.items}>
                {itemsSub.map(this.renderItem)}
              </div>
            </div>
            <div className={style.content}>
              {<CitySelect onSelect={this.hideCitySelect} active={this.state.showCitySelect} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps( state ) {
  return {
    cart: state.cart,
    city: state.city,
  };
}

export default withRouter(connectAppConfig(connect(mapStateToProps)(Menu)));
