import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from './style.styl';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';

class Header extends React.PureComponent {
  static propTypes = {
    left:   PropTypes.element,
    right:  PropTypes.element,
    center: PropTypes.string,
  };

  render() {
    const logoStyle = {
      backgroundImage: `url(${this.props.config.LOGO})`,
    };

    return (
      <div className={style.wrapper}>
        <div className={style.left}>
          {this.props.left}
        </div>
        <div className={style.right}>
          {this.props.right}
        </div>
        <div className={style.center}>
          {this.props.center
            ? <div className={style.title}>{this.props.center}</div>
            : <Link style={logoStyle} className={style.logo} to="/" />
          }
        </div>
      </div>
    );
  }
}

export default connectAppConfig(Header);
