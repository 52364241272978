import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { phone } from '../../../propTypes';
import { reachGoal } from '../../../utils/metrika';
import style from './style.styl';

import Cart from '../Cart';
import Icon from '../../../components/Icon';

const clearRegexp = /[^0-9]/g;

function PhoneButton(props) {
  if (!props.phone) return null;

  let number = (props.phone.code + props.phone.number).replace(clearRegexp, '');
  if (number.indexOf('8800') !== 0) number = `+${number}`;
  const footerStyle = { marginBottom: props.cartActive ? Cart.placeholerHeight : 0 };
  // {props.phone.code} <b>{props.phone.number}</b>
  return (
    <a href={`tel:${number}`} className={style.button} style={footerStyle} onClick={reachGoal('mobile_call_click')}>
      <div className={style.inner}>
        <Icon glyph="phone" className={style.icon} />
      </div>
    </a>
  );
}

PhoneButton.propTypes = {
  phone,
  cartActive: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    phone: state.phone,
  };
}

export default connect(mapStateToProps)(PhoneButton);
