import React, { Component } from 'react';
import Cookies              from 'js-cookie';
import classnames           from 'classnames';
import style                from './style.styl';
import * as propTypes       from '../../../propTypes';

import FooterMenu                      from '../../containers/FooterMenu';
import Icon                            from '../../../components/Icon';
import Phone                           from '../Phone';
import AppConfig, { connectAppConfig } from '../../../../shared/components/AppConfig';


class Footer extends Component {
  static propTypes = {
    location: propTypes.location,
    params:   propTypes.params,
  };

  wrapper = React.createRef();

  toMobile = () => {
    const { config } = this.props;
    const expires = new Date();
    expires.setYear(expires.getFullYear() + 1);
    Cookies.set(`env-${config.ID}`, 'mobile', { domain: config.DOMAIN, expires });
    window.location.reload();
  };

  render() {
    const { config } = this.props;
    return (
      <div>
        <div
          ref={this.wrapper}
          className={style.footer_placeholder}
        />
        <div className={style.footer}>
          <div className={style.wrapper}>
            <div className={style.container}>
              {config.MENU_HIDDEN !== true && <FooterMenu />}
              {config.MENU_HIDDEN !== true && (
                <React.Fragment>
                  <div className={style.contacts}>
                    {config.ADDRESS && (
                      <div className={style.contact}>
                        <Icon glyph="geo" className={style.icon} />
                        {config.ADDRESS}
                      </div>
                    )}
                    {config.EMAIL && (
                      <div className={style.contact}>
                        <Icon glyph="mail" className={classnames(style.icon, style.mail)} />
                        {config.EMAIL}
                      </div>
                    )}
                    <Phone>
                      {phone => (
                        <div className={style.contact}>
                          <Icon glyph="phone" className={style.icon} />
                          {phone}
                        </div>
                      )}
                    </Phone>
                    {config.MESSENGER_PHONE && (
                      <div className={style.contact}>
                        <Icon glyph="viber" className={style.icon} />
                        <Icon glyph="whatsapp" className={style.icon2} />
                        {config.MESSENGER_PHONE}
                      </div>
                    )}
                  </div>
                  <div className={style.copyright}>
                    <span dangerouslySetInnerHTML={{ __html: config.COPYRIGHT }} />
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className={style.disclaimer} dangerouslySetInnerHTML={{ __html: config.DISCLAIMER }} />
          </div>
        </div>
      </div>
    );
  }
}

export default connectAppConfig(Footer);
