import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import style from './style';
import * as API from '../../../utils/API';
import { setCities, setCity } from '../../../redux/api/actions';
import { cities as PropCities, city as PropCity } from '../../../propTypes';

import Search from '../../components/Search';
import { connectAppConfig } from 'src/shared/components/AppConfig';

class CitySelect extends Component {
  static propTypes = {
    city:     PropCity,
    cities:   PropCities,
    dispatch: PropTypes.func,
    active:   PropTypes.bool,
    onSelect: PropTypes.func,
  };

  static contextTypes = {
    store: PropTypes.shape(),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      cities:  null,
    };

    this.selectCity = this.selectCity.bind(this);
    this.searchCities = this.searchCities.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.props.active && nextProps.active) {
      if (!this.props.cities) {
        this.loadCities();
      } else {
        this.setState({ loading: false });
      }
    }
  }

  loadCities() {
    this.props.requester.send(API.cities('')).then((cities) => {
      this.props.dispatch(setCities(cities));
      this.setState({ loading: false });
    });
  }

  searchCities(title) {
    this.setState({ value: title, loading: true }, () => {
      if (!title) {
        this.setState({ cities: null, loading: false });
        return;
      }
      this.props.requester.send(API.cities(title)).then((cities) => {
        if (title === this.state.value) {
          this.setState({ cities, loading: false });
        }
      });
    });
  }

  selectCity(city) {
    this.props.dispatch(setCity(city));
    this.props.onSelect(city);
  }

  renderList() {
    const cities = this.state.cities || this.props.cities;
    const activeCity = this.props.city && this.props.city.id;

    if (!cities || !cities.length) return <div>Ничего не найдено</div>;

    return (
      <div className={style.cities}>
        {cities.map(city => (
          <div
            key={city.id}
            className={classnames(style.city, activeCity === city.id && style.city_active)}
            onClick={() => this.selectCity(city)}
          >
            {city.title}
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div>
        <Search onChange={this.searchCities} placeholder="Поиск города" value="" />
        {this.state.loading
          ? <div className={style.spinner} />
          : this.renderList()
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  city:   state.city,
  cities: state.cities,
});

export default connectAppConfig(connect(mapStateToProps)(CitySelect));
