import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './style.styl';

import Icon from '../../../components/Icon';
import Cart from '../Cart';
import Phone from '../Phone';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';

class Footer extends React.PureComponent {
  static propTypes = {
    cartActive: PropTypes.bool,
  };

  render() {
    const { props } = this;
    const { config: { MENU_HIDDEN, ADDRESS, EMAIL } } = props;
    const footerStyle = { paddingBottom: props.cartActive ? Cart.placeholerHeight : 0 };
    return (
      <div>
        <div className={style.footer_placeholder} />
        {MENU_HIDDEN !== true && (
          <React.Fragment>
            <div className={style.footer} style={footerStyle}>
              {ADDRESS && (
                <div className={style.contact}>
                  <Icon glyph="geo" className={classNames(style.icon, style.geo)} />
                  {ADDRESS}
                </div>
              )}
              {EMAIL && (
                <div className={style.contact}>
                  <Icon glyph="mail" className={classNames(style.icon, style.mail)} />
                  {EMAIL}
                </div>
              )}
              <Phone>
                {phone => (
                  <div className={style.contact}>
                    <Icon glyph="phone" className={style.icon} />
                    {phone}
                  </div>
                )}
              </Phone>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default connectAppConfig(Footer);
