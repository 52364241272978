import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import style from './style';

export default function HeaderMenuItem({ item, active }) {
  return <Link className={classNames(style.link, { [style.link_active]: active })} to={item.link}>{item.name}</Link>;
}

HeaderMenuItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
  active: PropTypes.bool,
};
