import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './style';
import * as scroll from '../../../utils/scroll';
import get from '../../../utils/get';

import Icon from '../../../components/Icon';

const VISIBLE_THRESHOLD = 100;
const BOTTOM_OFFSET = 50;

class ScrollTop extends Component {
  static propTypes = {
    parent: PropTypes.shape(),
    footer: PropTypes.shape(),
  };

  // static defaultProps = {
  //   footerHeight: 0,
  // };

  static scrollTop() {
    scroll.toTop();
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      // lastPosition: 0,
      visible:      false,
      width:        0,
      windowHeight: 0,
      buttonOffset: 50,
      hoveredForce: false,
    };

    // this.scrollTop = this.scrollTop.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onResize.bind(this);
    this.calcWidth = this.calcWidth.bind(this);
    this._onHoverForce = this._onHoverForce.bind(this);
    this._onBlurForce = this._onBlurForce.bind(this);
  }

  componentDidMount() {
    if (this.props.footer) this.calcFooterHeight();
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);
    this.onHover = scroll.on('hover', this._onHoverForce);
    this.onBlur = scroll.on('blur', this._onBlurForce);
    this.onScroll();
    this.onResize();
  }

  componentDidUpdate(pP) {
    const { parent, footer } = this.props;
    if (get(parent, 'current') !== get(pP.parent, 'current')) {
      this.calcWidth();
    }
    if (get(footer, 'current') !== get(pP.footer, 'current')) {
      this.calcFooterHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
    this.onHover();
    this.onBlur();
  }

  onResize() {
    this.calcWidth();
    this.setState({ windowHeight: window.innerHeight });
  }

  onScroll() {
    const { body } = document;
    const bodyHeight = body.clientHeight;
    const scrollTop = scroll.currentOffset();
    if (scrollTop + this.state.windowHeight > bodyHeight - this.state.footerHeight) {
      this.setState({ buttonOffset: (scrollTop + this.state.windowHeight - bodyHeight + this.state.footerHeight) + BOTTOM_OFFSET });
    } else {
      this.setState({ buttonOffset: BOTTOM_OFFSET });
    }
    if (scrollTop > VISIBLE_THRESHOLD && !this.state.visible) {
      this.setState({ visible: true });
    } else if (scrollTop < VISIBLE_THRESHOLD && this.state.visible) {
      this.setState({ visible: false });
    }
  }

  _onHoverForce() {
    this.setState({ hoveredForce: true });
  }

  _onBlurForce() {
    this.setState({ hoveredForce: false });
  }

  calcWidth() {
    const parent = get(this.props, ['parent', 'current']);
    if (!parent) return;
    const width = parent.offsetLeft;
    this.setState({ width, narrow: width < 69 });
  }

  calcFooterHeight() {
    const footer = get(this.props, ['footer', 'current', 'wrapper', 'current']);
    if (!footer) return;
    const footerHeight = footer.clientHeight;
    this.setState({ footerHeight });
  }

  render() {
    if (!this.state.visible) return null;
    const classes = classNames(
      style.scroll_top,
      this.state.narrow && style.narrow,
      this.state.hoveredForce && style.hover,
    );
    return (
      <div onClick={this.constructor.scrollTop} className={classes} style={{ width: this.state.width }}>
        <div className={style.highlight} />
        <div className={style.button} style={{ bottom: this.state.buttonOffset }}>
          <Icon glyph="arrow_up" className={style.icon} />
        </div>
      </div>
    );
  }
}

export default ScrollTop;
