const vendors = ['ms', 'moz', 'webkit', 'o'];

function getRequestAnimationFrame() {
  if (!process.env.CLIENT) return () => {};

  let func;

  if (window.requestAnimationFrame) func = window.requestAnimationFrame;

  for (let x = 0; x < vendors.length && !func; ++x) {
    const property = `${vendors[x]}RequestAnimationFrame`;
    func = window[property];
  }

  if (!func) {
    let lastTime = 0;
    func = (callback) => {
      const currTime = new Date().getTime();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));
      const id = window.setTimeout(() => {
        callback(currTime + timeToCall);
      }, timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };
  }

  return func.bind(window);
}

function getCancelAnimationFrame() {
  if (!process.env.CLIENT) return () => {};

  let func;

  if (window.cancelAnimationFrame) func = window.cancelAnimationFrame;

  for (let x = 0; x < vendors.length && !func; ++x) {
    const property = `${vendors[x]}CancelAnimationFrame`;
    const propertyAlt = `${vendors[x]}CancelRequestAnimationFrame`;
    func = window[property] || window[propertyAlt];
  }

  if (!func) {
    func = (id) => {
      clearTimeout(id);
    };
  }

  return func.bind(window);
}

export const requestAnimationFrame = getRequestAnimationFrame();
export const cancelAnimationFrame = getCancelAnimationFrame();
