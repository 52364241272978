const dictionary = {
  wrong_confirmation_code:             'Неверный код подтверждения',
  wrong_confirmation_code_need_resend: 'Вы слишком много раз неверно ввели код. Запросите новый.',
  not_null:                            'Поле не может быть пустым',
  invalid_email:                       'Неправильный формат',
  email_in_use:                        'Адрес уже используется',
  username_not_registered:             'Пользователь с такой почтой не найден',
  invalid_username_or_password:        'Неверный пароль',
  user_not_confirmed:                  'Адрес не подтвержден',
  invalid_captcha_code:                'Введите символы с картинки',
  already_requested:                   'На данный адрес уже выслано письмо',
  order_not_found:                     'Заказ с таким номером не найден',
  confirm_required:                    'Вы не согласились с пользовательским соглашением и политикой конфиденциальности',
};

export default function l10n(string) {
  return dictionary[string] || string;
}
