/**
 * Проверяет, является узел element дочерним для узла parent
 * @param {Node} element
 * @param {Node} parent
 * @return {boolean}
 */
export default function isParentNode(element, parent) {
  let el = element;
  do {
    if (el === parent) return true;
    el = el.parentElement;
  } while (el);
  return false;
}
