import React from 'react';
import { connect } from 'react-redux';
import { phone as phoneProp } from '../../../propTypes';

function Phone(props) {
  const { phone, children } = props;
  if (!phone) return null;

  const { code, number } = phone;
  const el = <span>{code} <b>{number}</b></span>;

  if (typeof children === 'function') return children(el);
  return el;
}

Phone.propTypes = {
  phone:  phoneProp,
};

function mapStateToProps(state) {
  return {
    phone: state.phone,
  };
}

export default connect(mapStateToProps)(Phone);
