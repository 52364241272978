import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import * as propTypes from '../../../propTypes';
import style from './style';

import Icon from '../../../components/Icon';
import Price from 'src/shared/components/Price';
import Button from '../Button';

function Cart(props) {
  const placeholderStyle = props.active ? { height: Cart.placeholerHeight } : {};
  return (
    <div>
      <div className={classnames(style.cart_placeholder)} style={placeholderStyle} />
      <div className={classnames(style.cart, props.active && style.active)}>
        <div className={style.button}>
          <Button tag="a" to="/cart" filled>Оформить</Button>
        </div>
        <Icon glyph="cart" className={classnames(style.icon, style.line)} />
        <div className={classnames(style.total, style.line)}>{props.cart.total.quantity}</div>
        <div className={classnames(style.summ, style.line)}><Price value={props.cart.total.summ} /></div>
      </div>
    </div>
  );
}

Cart.propTypes = {
  cart:   propTypes.cart,
  active: PropTypes.bool,
};

Cart.placeholerHeight = 55;

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

export default connect(mapStateToProps)(Cart);
