import * as React                      from 'react';
import PropTypes                       from 'prop-types';
import { Link }                        from 'react-router-dom';
import Button                          from "src/shared/desktop/components/Button";
import style                           from './style.styl';
import { params, location }            from '../../../propTypes';
import HeaderMenu                      from '../../components/HeaderMenu';
import HeaderCart                      from '../HeaderCart';
import Phone                           from '../Phone';
import Icon                            from '../../../components/Icon';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';

const menuItems = [
  {
    name: 'О компании',
    link: '/page/about',
  },
  {
    name: 'Доставка',
    link: '/page/delivery',
  },
  {
    name: 'Как купить',
    link: '/page/how',
  },
  // {
  //   name: 'Помощь покупателю',
  //   link: '/page/help'
  // },
  // {
  //   name: 'Что с моим заказом?',
  //   link: '/check_order',
  // },
  {
    name: 'Франшиза',
    link: '/page/franchise',
  },
  {
    name: 'Новости',
    link: '/news',
  },
];

class Header extends React.PureComponent {
  static propTypes = {
    query: PropTypes.string,
    location,
    params,
  };

  render() {
    const { props } = this;
    const { config } = props;
    const logoStyle = {
      backgroundImage: `url(${config.LOGO})`,
    };

    return (
      <div className={style.header}>
        <div className={style.row}>
          <div className={style.logo}>
            <Link className={style.logoImage} style={logoStyle} to="/" />
          </div>

          <div className={style.aside}>
            <Phone showFreeLabel>
              {phone => (
                <div className={style.phone}>
                  <Icon glyph="phone" className={style.phone_icon} />
                  <div className={style.phone_text}>{phone}</div>
                </div>
              )}
            </Phone>

            <Button  tag="a" to="/page/contacts" filled>Контакты</Button>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.menu}>
            {config.MENU_HIDDEN !== true && <HeaderMenu location={props.location} items={menuItems} />}
          </div>

          <div className={style.cart}>
            <HeaderCart />
          </div>
        </div>
      </div>
    );
  }
}

export default connectAppConfig(Header);
