import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from './style';
import * as propTypes from '../../../propTypes';

import Price from '../../../components/Price';
import Icon from '../../../components/Icon';

function HeaderCart(props) {
  const { cart } = props;
  const total = cart.cart.reduce((t, item) => t + +item.quantity, 0);
  return (
    <Link className={style.link} to="/cart">
      <Icon glyph="cart" className={classnames(style.icon, style[`icon_${props.size}`])} />
      <div className={style.count}>{total}</div>
      <div className={style.summary}><Price value={cart.total.summ} /></div>
    </Link>
  );
}

HeaderCart.propTypes = {
  cart: propTypes.cart,
  size: PropTypes.string,
};

HeaderCart.defaultProps = {
  size: 'medium',
};

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

export default connect(mapStateToProps)(HeaderCart);
