import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as API from '../../../utils/API';
import { setUser } from '../../../redux/api/actions';
import style from './style';
import * as propTypes from '../../../propTypes';
import isParentNode from '../../../utils/isParentNode';

import Icon from '../../../components/Icon';
import { connectAppConfig } from 'src/shared/components/AppConfig';

class HeaderProfile extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history:  propTypes.history,
    location: propTypes.location,
    profile:  propTypes.profile,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      active: false,
    };

    this.logoutAction = this.logoutAction.bind(this);
    this.activate = this.activate.bind(this);
    this.deactivate = this.deactivate.bind(this);
    this.toggle = this.toggle.bind(this);

    this.menu = [
      { name: 'Профиль', link: '/cabinet' },
      { name: 'Заказы', link: '/cabinet/orders' },
      // { name: 'Настройки', link: '/profile' },
      { name: 'Выход', action: this.logoutAction },
    ];
  }

  componentWillUnmount() {
    if (this.state.active) this.deactivate();
  }

  onClick(e, item) {
    if (item.action) item.action(e);
    if (item.link) this.props.history.push(item.link);
    this.deactivate();
  }

  activate() {
    this.setState({ active: true });

    if (!this.state.active) {
      window.addEventListener('mousedown', this.deactivate);
    }
  }

  deactivate(e) {
    if (e && e.target !== this.component && isParentNode(e.target, this.component)) return;

    this.setState({ active: false });
    window.removeEventListener('mousedown', this.deactivate);
  }

  toggle() {
    if (this.state.active) {
      this.deactivate();
    } else {
      this.activate();
    }
  }

  logoutAction(e) {
    e.preventDefault();
    this.props.requester.send(API.login()).then((res) => {
      this.props.dispatch(setUser(res));
    });
  }

  render() {
    if (this.props.profile) {
      return (
        <div className={style.wrapper}>
          <div
            className={classnames(style.popup, this.state.active && style.popup_active)}
            ref={(c) => { this.component = c; }}
          >
            <div onClick={this.toggle} className={style.header}>
              <Icon glyph="profile" className={style.icon} />
              Личный кабинет
              <div className={style.arrow} />
            </div>
            <div className={classnames(style.popup_content, this.state.active && style.popup_content_active)}>
              <div className={style.email}>
                <div className={style.email_inner}>{this.props.profile.email}</div>
              </div>
              <div className={style.menu}>
                {this.menu.map(item => (
                  <Link
                    key={item.name}
                    className={style.item}
                    to={item.link || ''}
                    onClick={e => this.onClick(e, item)}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
    const loc = this.props.location;
    const link = encodeURIComponent(`${loc.pathname}${loc.search}`);
    return (
      <Link to={`/login?redirect=${link}`} className={style.login}>
        <Icon glyph="profile" className={style.login_icon} />
        <div className={style.login_text}>Вход / Регистрация</div>
      </Link>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

export default withRouter(connectAppConfig(connect(mapStateToProps)(HeaderProfile)));
