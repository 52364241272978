import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import classNames           from 'classnames';
import PropTypes            from 'prop-types';
import Icon                 from "src/shared/components/Icon";
import Button               from "src/shared/desktop/components/Button";
import Phone                from "src/shared/desktop/components/Phone";
import style                from './style';
import { params, location } from '../../../propTypes';
import triggerEvent         from '../../../utils/triggerEvent';
import * as scroll          from '../../../utils/scroll';

import HeaderCart                      from '../HeaderCart';
import HeaderTabs                      from '../HeaderTabs';
import SearchInput                     from '../SearchInput';
import HeaderProfile                   from '../HeaderProfile';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';
// import WarningMessage from '../WarningMessage';
// import Button from '../Button';

const VISIBLE_THRESHOLD = 120;

class HeaderFixed extends Component {
  static propTypes = {
    query: PropTypes.string,
    location,
    params,
  };

  static HEIGHT = 80;

  constructor( props, context ) {
    super(props, context);

    this.state = {
      active: false,
    };

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const scrollTop = scroll.currentOffset();
    if (scrollTop > VISIBLE_THRESHOLD && !this.state.active) {
      this.setState({ active: true });
    }
    if (scrollTop < VISIBLE_THRESHOLD && this.state.active) {
      this.setState({ active: false });
      triggerEvent(window, 'mousedown');
    }
  }

  render() {
    const logoStyle = {
      backgroundImage: `url(${this.props.config.LOGO})`,
    };

    return (
      <div className={classNames(style.header, this.state.active && style.active)}>
        <div className={style.wrapper}>
          <div className={style.logo}>
            <Link className={style.logoImage} style={logoStyle} to="/" />
          </div>
          {/*<div className={style.aside}>*/}
            <div className={style.contacts}>
              <Phone showFreeLabel>
                {phone => (
                  <div className={style.phone}>
                    <Icon glyph="phone" className={style.phone_icon} />
                    <div className={style.phone_text}>{phone}</div>
                  </div>
                )}
              </Phone>

              <Button tag="a" to="/page/contacts" filled>Контакты</Button>
            </div>
            <div className={style.cart}>
              <HeaderCart size="small" />
            </div>
          {/*</div>*/}
        </div>
        {/* <WarningMessage /> */}
      </div>
    );
  }
}

export default connectAppConfig(HeaderFixed);
