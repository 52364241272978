import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './style.styl';
import { setCarType } from '../../../redux/api/actions';

class HeaderTabs extends Component {
  static propTypes = {
    carType:  PropTypes.string,
    dispatch: PropTypes.func,
  };

  static contextTypes = {
    router: PropTypes.object,
    store:  PropTypes.object,
  };

  selectCarType(type) {
    this.props.dispatch(setCarType(type));
  }

  renderTab(type, text) {
    const { carType } = this.props;
    const className = classNames(style.tab, { [style.tab_active]: carType === type });
    return <div className={className} onClick={() => this.selectCarType(type)}>{text}</div>;
  }

  render() {
    return (
      <div className={style.tabs}>
        <div className={classNames(style.tab, style.tab_active)}>Поиск по сайту</div>
        {/* {this.renderTab('cars', 'Поиск по легковым')} */}
        {/* {this.renderTab('trucks', 'Поиск по грузовым')} */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  carType: state.carType,
});
export default connect(mapStateToProps)(HeaderTabs);
