import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './style';

import Input from '../Input';
import Icon from '../../../components/Icon';

class Search extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onChange:    PropTypes.func,
  };

  static defaultProps = {
    placeholder: 'Поиск запчастей',
    onChange:    () => {},
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      value: '',
    };

    this.onSearch = this.onSearch.bind(this);
    this.clear = this.clear.bind(this);
  }

  onSearch(input) {
    const { value } = input.state;
    this.setState({ value });
    this.props.onChange(value);
  }

  clear() {
    this.setState({ value: '' });
    this.props.onChange('');
  }

  render() {
    return (
      <div className={style.wrapper}>
        <Input onChange={this.onSearch} placeholder={this.props.placeholder} value={this.state.value} />
        {this.state.value && (
          <div onClick={this.clear}>
            <Icon glyph="close" className={style.clear} />
          </div>
        )}
        <Icon glyph="search" className={style.icon} />
      </div>
    );
  }
}

export default Search;
