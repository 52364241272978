import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { hideGallery } from '../../../redux/api/actions';
import style from './style';
import generateBrand from '../../../utils/generateBrand';
import * as propTypes from '../../../propTypes';
import partPhoto from '../../../utils/partPhoto';

import Icon from '../../../components/Icon';
import { connectAppConfig } from 'src/shared/components/AppConfig';

// import WarningMessagePhoto from '../WarningMessagePhoto';

class PartPhotosPopup extends React.PureComponent {
  static propTypes = {
    visible:  PropTypes.bool,
    part:     propTypes.part,
    dispatch: PropTypes.func,
    index:    PropTypes.number,
  };

  static loadImage(src, cb) {
    const image = new Image();
    image.onload = cb;
    image.src = src;
  }

  static prevent(e) {
    e.preventDefault();
  }

  constructor(props) {
    super(props);

    this.state = {
      width:   null,
      height:  null,
      current: 0,
      image:   null,
    };

    this.components = {};

    this.thumb = this.thumb.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.close = this.close.bind(this);
    this.calculateContainer = this.calculateContainer.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  componentDidMount() {
    this.body = document.body;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ current: nextProps.index });
  }

  componentDidUpdate(prevProps) {
    if (process.env.CLIENT) {
      if (prevProps.visible !== this.props.visible) {
        if (this.props.visible) {
          this.activate();
        } else {
          this.deactivate();
        }
      }
    }
  }

  setPhoto(i) {
    const { photos } = this.props.part;
    const token = this.props.config.PHOTO_TOKEN;
    const photo = partPhoto(photos[i], '1024x1024', token);
    const photoNext = partPhoto(photos[this.convertIndex(i + 1)], '1024x1024', token);
    const photoPrev = partPhoto(photos[this.convertIndex(i - 1)], '1024x1024', token);
    this.constructor.loadImage(photo, () => {
      this.setState({ image: photo });
      setTimeout(() => this.calculateContainer());
      this.constructor.loadImage(photoNext);
      this.constructor.loadImage(photoPrev);
    });
    this.setState({ current: i, image: null });
  }

  // https://github.com/dimsemenov/Magnific-Popup/blob/master/src/js/core.js#L787
  getScrollbarSize() {
    if (this.scrollbarSize === undefined) {
      const scrollDiv = document.createElement('div');
      scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
      document.body.appendChild(scrollDiv);
      this.scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
    }
    return this.scrollbarSize;
  }

  calculateContainer() {
    const { image, wrapper, container } = this.components;

    const imageRate = image.naturalWidth / image.naturalHeight;
    const zoom = image.naturalWidth / wrapper.clientWidth;
    let newWidth = Math.min(image.naturalWidth / zoom, container.clientWidth, 1024);
    let newHeight = newWidth / imageRate;

    if (newHeight > wrapper.clientHeight) {
      newHeight = wrapper.clientHeight;
      newWidth = newHeight * imageRate;
    }
    this.setState({ width: Math.round(newWidth) || 0, height: Math.round(newHeight) || 0 });
  }

  keyDown(e) {
    switch (e.keyCode) {
      case 37: // LEFT ARROW
        this.prev(e);
        break;
      case 39: // RIGHT ARROW
        this.next(e);
        break;
      case 27: // ESC
        this.close(e);
        break;
      // no default
    }
  }

  thumb(photo, i) {
    const token = this.props.config.PHOTO_TOKEN;
    const p = partPhoto(photo, '72x54', token);
    const pFull = partPhoto(photo, '', token);
    return (
      <a href={pFull} key={i} target="_blank" rel="noopener noreferrer" onClick={e => this.selectPhoto(e, i)}>
        <img
          className={classNames(style.thumb, { [style.active]: this.state.current === i })}
          src={p}
          width={72}
          height={54}
          alt=""
        />
      </a>
    );
  }

  selectPhoto(e, i) {
    e.preventDefault();
    this.setPhoto(i);
  }

  activate() {
    this.body.classList.add('hidden');
    this.body.style.marginRight = `${this.getScrollbarSize()}px`;
    this.body.addEventListener('touchmove', this.prevent);

    // this.touch = new Hammer.Manager(this.refs.gallery);
    // this.touch.add(new Hammer.Pan({direction: Hammer.DIRECTION_VERTICAL, treshold: 10}));
    // this.touch.on('panstart panmove panend pancancel', this.onPan);

    window.addEventListener('resize', this.calculateContainer);
    window.addEventListener('keydown', this.keyDown);
    this.setPhoto(this.state.current);
    // this.calculateContainer();
  }

  next(e) {
    e.preventDefault();
    const totalPhotos = this.props.part.photos.length;
    const nextPhoto = this.state.current + 1;
    this.setPhoto(nextPhoto >= totalPhotos ? 0 : nextPhoto);
  }

  prev(e) {
    e.preventDefault();
    const totalPhotos = this.props.part.photos.length;
    const nextPhoto = this.state.current - 1;
    this.setPhoto(nextPhoto < 0 ? totalPhotos - 1 : nextPhoto);
  }

  deactivate() {
    this.body.classList.remove('hidden');
    this.body.style.marginRight = 0;
    this.body.removeEventListener('touchmove', this.prevent);
    window.removeEventListener('resize', this.calculateContainer);
    window.removeEventListener('keydown', this.keyDown);
  }

  convertIndex(i) {
    const { photos } = this.props.part;
    if (i > photos.length - 1) return 0;
    if (i < 0) return photos.length - 1;
    return i;
  }

  close(e) {
    e.preventDefault();
    this.props.dispatch(hideGallery());
  }

  render() {
    if (!this.props.visible) return null;

    const { part } = this.props;
    if (!part) return null;
    const { photos } = part;

    return (
      <div ref={(c) => { this.components.gallery = c; }} className={style.wrapper}>
        <div ref={(c) => { this.components.container = c; }} className={style.container}>
          <div ref={(c) => { this.components.wrapper = c; }} className={style.photo_wrapper}>
            <div
              ref={(c) => { this.components.photo = c; }}
              className={style.photo}
              style={{ width: this.state.width, height: this.state.height }}
            >
              {this.state.image
                ? (
                  <span>
                    <div className={style.header}>
                      <div className={style.right}>артикул {part.barcode}</div>
                      <div className={style.left}>
                        {part.partName ? part.partName.title : null} {generateBrand(part.relatedBrand)}
                      </div>
                    </div>
                    <img
                      ref={(c) => { this.components.image = c; }}
                      className={style.image}
                      src={this.state.image}
                      alt=""
                    />
                  </span>
                ) : <div className={style.loading} />}
              <div className={style.prev} onClick={this.prev}><Icon glyph="arrow_left" className={style.arrow_icon} />
              </div>
              <div className={style.next} onClick={this.next}><Icon glyph="arrow_right" className={style.arrow_icon} />
              </div>
              {/* {this.state.image && <WarningMessagePhoto />} */}
            </div>
          </div>
        </div>
        <div className={style.close} onClick={this.close}><Icon glyph="close" className={style.close_icon} /></div>
        <div className={style.thumbs}>
          {photos.map(this.thumb)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    visible: state.galleryPopup.visible,
    part:    state.galleryPopup.item,
    index:   state.galleryPopup.index,
  };
}

export default connectAppConfig(connect(mapStateToProps)(PartPhotosPopup));
