import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'alfamt-cars-logotypes/build/css/sprite-cars-20.css';
import 'alfamt-cars-logotypes/build/css/sprite-trucks-20.css';
import style from './style';
import * as propTypes from '../../../propTypes';

// import Search from '../../components/Search';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import Cart from '../../components/Cart';
import Button from '../../components/Button';
import PhoneButton from '../../components/PhoneButton';
import Router from '../../../components/Router';

const renderMenuButton = () => {
  return (
    <label htmlFor="menu">
      <Button tag="block" filled icon="hamburger" size="sm" />
    </label>
  );
};

const back = () => {
  window.history.back();
};

class App extends Component {
  static propTypes = {
    params:   propTypes.params,
    location: propTypes.location,
    routes:   propTypes.routes,
    dispatch: PropTypes.func,
    children: PropTypes.element,
    notFound: PropTypes.bool,
    cart:     propTypes.cart,
    route:    propTypes.route,
  };

  static childContextTypes = {
    params:   PropTypes.object,
    location: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      // menuVisible: false,
      component:   null,
      headerProps: this.getHeader(),
      cartActive:  false,
    };
  }

  getChildContext() {
    return {
      params:   this.props.params,
      location: this.props.location,
    };
  }

  getRouteComponent() {
    const { children } = this.props;
    return children && children.type;
  }

  getHeader(component) {
    const defaultHeader = {
      right: renderMenuButton(),
    };
    if (component && typeof component.getHeader === 'function') {
      return Object.assign(
        defaultHeader,
        component.getHeader(this.props.params),
        { left: <Button onClick={back} filled icon="arrow_left" size="sm" /> }
      );
    }
    return defaultHeader;
  }

  getCartActive(component) {
    if (component.cartActive !== undefined) return component.cartActive;
    return !!this.props.cart.total.quantity;
  }

  setRouteData(RenderComponent) {
    if (RenderComponent === this.state.component) return;
    // RenderComponent.preload().then(({ default: component }) => {
      this.setState({
        cartActive:  this.getCartActive(RenderComponent),
        headerProps: this.getHeader(RenderComponent),
        component:   RenderComponent,
      });
    // });
  }

  renderContent = (RenderComponent, renderProps) => {
    this.setRouteData(RenderComponent);
    return <RenderComponent {...renderProps} />;
  };

  render() {
    const { cartActive, headerProps } = this.state;
    return (
      <div className={style.page}>
        <Menu />
        <Header {...headerProps} />
        <div className={style.content}>
          {/* <Search /> */}

          <Router key="app" routes={this.props.route.routes} render={this.renderContent} />
        </div>
        <Footer cartActive={cartActive} />
        <PhoneButton cartActive={cartActive} />
        <Cart active={cartActive} />
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    notFound: store.notFound,
    cart:     store.cart,
    profile:  store.profile,
  };
}

export default connect(mapStateToProps)(App);
