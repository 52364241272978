export default function generateBrand(brand) {
  if (!brand) return '';
  const brandTitle = brand.brand ? brand.brand.title : '';
  const modelTitle = brand.model ? brand.model.title : '';
  const generationTitle = brand.generation ? brand.generation.title : '';

  return `${brandTitle} ${generationTitle || modelTitle}`;
}

export function generatePartAlt(part) {
  return `${(part.partName ? part.partName.title : null)} ${generateBrand(part.relatedBrand)}`;
}
