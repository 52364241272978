import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import 'alfamt-cars-logotypes/build/css/sprite-cars-20.css';
import 'alfamt-cars-logotypes/build/css/sprite-trucks-20.css';
import style from './style.styl';
// import { setLastParams } from '../../../redux/api/actions';

import * as propTypes from '../../../propTypes';
import HeaderFixed from '../../components/HeaderFixed';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CartPopup from '../../containers/CartPopup';
import ScrollTop from '../../components/ScrollTop';
import PartPhotosPopup from '../../components/PartPhotosPopup';
import Router from '../../../components/Router';

class App extends Component {
  static propTypes = {
    params:   propTypes.params,
    location: propTypes.location,
    query:    PropTypes.shape(),
    // routes:   propTypes.routes,
    route:    propTypes.route,
    dispatch: PropTypes.func,
    children: PropTypes.element,
    loaded:   PropTypes.bool,
    notFound: PropTypes.bool,
  };

  static childContextTypes = {
    params:   PropTypes.object,
    location: PropTypes.object,
  };

  wrapper = React.createRef();
  footer = React.createRef();

  getChildContext() {
    return {
      params:   this.props.params,
      location: this.props.location,
    };
  }

  // componentDidMount() {
  //   this.props.dispatch(setLastParams(this.props.params));
  // }
  //
  // componentDidUpdate() {
  //   this.props.dispatch(setLastParams(this.props.params));
  // }

  render() {
    const { params, location, query: { text }, loaded, route: { routes } } = this.props;
    // const key = this.props.routes[this.props.routes.length - 1].name || this.props.location.pathname;
    return (
      <div className={style.wrapper}>
        <Header params={params} location={location} query={text} />
        <HeaderFixed params={params} location={location} query={text} />
        <div className={style.content}>
          <ScrollTop parent={this.wrapper} footer={this.footer} />
          <div ref={this.wrapper} className={classNames(style.content_inner, !loaded && style.loading)}>
            <Router routes={routes} />
          </div>
        </div>
        <Footer ref={this.footer} params={params} location={location} />
        <CartPopup />
        <PartPhotosPopup visible />
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    notFound: store.notFound,
    cart:     store.cart,
    profile:  store.profile,
    // loaded:   store.reduxAsyncConnect.loaded,
  };
}

export default connect(mapStateToProps)(App);
