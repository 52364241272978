import React from 'react';
import PropTypes from 'prop-types';
import style from './style';
import * as propTypes from '../../../propTypes';

import HeaderMenuItem from '../HeaderMenuItem';

export default function HeaderMenu(props) {
  const { items, location } = props;
  const menuItems = [];
  // хак на добавление текстовой ноды между элементами списка, чтобы они растягивались от text-align: justify
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    menuItems.push(<HeaderMenuItem active={location.pathname.indexOf(item.link) !== -1} key={item.name} item={item} />);
    menuItems.push(' ');
  }
  return <nav className={style.menu}>{menuItems}</nav>;
}

HeaderMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  })),
  location: propTypes.location,
};
