import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as propTypes from '../../../propTypes/index';
import style from './style.styl';

class Phone extends Component {
  static propTypes = {
    showFreeLabel: propTypes.PropTypes.bool,
    phone:         propTypes.phone,
    localPhone:    propTypes.phone,
    location:      propTypes.location,
    children:      propTypes.PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      phone:    props.localPhone || props.phone,
      location: props.location.pathname + props.location.search,
    };
  }

  componentWillReceiveProps(nextProps) {
    const nextLocation = nextProps.location.pathname + nextProps.location.search;
    const phone = nextLocation === this.state.location && nextProps.localPhone
      ? nextProps.localPhone
      : nextProps.phone;
    this.setState({ phone });
  }

  renderFreeLabel() {
    const { phone } = this.state;
    if (phone.code !== '8 (800)') return null;
    return <div className={style.free}>звонок бесплатный</div>;
  }

  renderPhone() {
    const { phone } = this.state;
    const { showFreeLabel } = this.props;
    return (
      <div className={style.phone}>
        {phone.code} <b>{phone.number}</b>
        {showFreeLabel && this.renderFreeLabel()}
      </div>
    );
  }

  render() {
    const { phone } = this.state;
    const { children } = this.props;
    if (!phone) return null;

    if (typeof children === 'function') return children(this.renderPhone());
    return this.renderPhone();
  }
}

const mapStateToProps = state => ({
  phone:      state.phone,
  localPhone: state.localPhone,
});
export default withRouter(connect(mapStateToProps)(Phone));
