import { requestAnimationFrame } from './requestAnimationFrame';

const eventsListeners = {};
let supportPageOffset = false;
let isCSS1Compat = false;

if (process.env.CLIENT) {
  supportPageOffset = window.pageXOffset !== undefined;
  isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
}

/**
 * Вычисляет позицию элемента относительно верха страницы
 * @param {HTMLElement} anchor
 * @return {Number} - позиция элемента относительно верха страницы
 */
export function getElementOffset(anchor) {
  let offset = 0;
  let el = anchor;
  if (anchor && anchor.offsetParent) {
    do {
      offset += el.offsetTop;
      el = el.offsetParent;
    } while (el);
  }
  return Math.max(offset, 0);
}

/**
 * Проверяет, является ли параметр DOM-элементом
 * @param o
 * @return {Boolean}
 */
function isNode(o) {
  return typeof Node === 'object'
    ? o instanceof Node
    : o && typeof o === 'object' && typeof o.nodeType === 'number' && typeof o.nodeName === 'string';
}

export function currentOffset() {
  if (supportPageOffset) return window.pageYOffset;
  if (isCSS1Compat) return document.documentElement.scrollTop;
  return document.body.scrollTop;
}

/**
 * Скролит страницу к указанной позиции или элементу
 * @param {Number|HTMLElement} offset - смещение относительно верха или элемент
 * @param {Number} [margin] - дополнительное смещение (для шапки)
 */
export function to(offset, margin = 0) {
  if (isNode(offset)) {
    to(getElementOffset(offset), margin);
    return;
  }
  if (currentOffset() <= offset) return;
  let topOffset = offset + 6 - margin;
  window.scrollTo(0, topOffset);

  function closer() {
    window.scrollTo(0, topOffset);
    topOffset -= 1;
    if (topOffset >= offset - margin) setTimeout(() => requestAnimationFrame(closer), 0);
  }

  closer();
}

/**
 * Скролит страницу к верху
 */
export function toTop() {
  to(0);
}

export function on(key, cb) {
  if (!eventsListeners[key]) {
    eventsListeners[key] = [];
  }
  eventsListeners[key].push(cb);
  return () => {
    eventsListeners[key] = eventsListeners[key].filter(f => f !== cb);
  };
}

export function trigger(key) {
  if (Array.isArray(eventsListeners[key])) {
    eventsListeners[key].forEach(cb => cb());
  }
}
