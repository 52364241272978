import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './style';
// import * as API from '../../../utils/API';
import { searchPage } from '../../../utils/links';
import isParentNode from '../../../utils/isParentNode';
import * as propTypes from '../../../propTypes';

import Button from '../../components/Button';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';

class SearchInput extends Component {
  static propTypes = {
    history: propTypes.history,
    query:   PropTypes.string,
    carType: PropTypes.string,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      // isBrandPopupActive: false,
      query:       this.props.query || '',
      queryBuffer: this.props.query || '',
      // type:               'cars',
      suggest:     [],
      selected:    -1,
      active:      false,
    };
    this.search = this.search.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.deactivate = this.deactivate.bind(this);
    this.activate = this.activate.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.query !== nextProps.query) {
      const nextQuery = nextProps.query || '';
      this.setState({
        query: nextQuery,
        // isBrandPopupActive: false,
      });
    }
  }

  search(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const q = this.searchInput.value;
    if (!q) return;
    const { carType } = this.props;
    const link = searchPage({ q, type: carType });
    this.props.history.push(link);
    this.deactivate();
  }

  searchChange(e) {
    const query = e.target.value;
    // API.searchSuggest(query).then(suggest => this.setState({ suggest, selected: -1 }));
    this.setState({ query, queryBuffer: query });
  }

  activate() {
    this.setState({ active: true });
    window.addEventListener('keydown', this.keyDown);
    window.addEventListener('mousedown', this.deactivate);
  }

  deactivate(e) {
    if (e && e.target !== this.suggest && isParentNode(e.target, this.suggest)) return;
    this.setState({ active: false });
    this.searchInput.blur();
    window.removeEventListener('keydown', this.keyDown);
    window.removeEventListener('mousedown', this.deactivate);
  }

  keyDown(e) {
    switch (e.keyCode) {
      case 38: // UP ARROW
        this.prev(e);
        break;
      case 40: // DOWN ARROW
        this.next(e);
        break;
      case 27: // ESC
      case 9: // TAB
        this.deactivate();
        break;

      // no default
    }
  }

  prev(e) {
    e.preventDefault();
    const selected = Math.max(this.state.selected - 1, -1);
    this.setState({
      selected,
      query: selected === -1 ? this.state.queryBuffer : this.state.suggest[selected],
    });
  }

  next(e) {
    e.preventDefault();
    const selected = Math.min(this.state.selected + 1, this.state.suggest.length - 1);
    this.setState({
      selected,
      query: this.state.suggest[selected],
    });
  }

  select(e, item) {
    e.preventDefault();
    this.setState({ query: item, queryBuffer: item }, () => this.search());
  }

  hover(i) {
    this.setState({ selected: i });
  }

  render() {
    const { config } = this.props;
    return (
      <form action="/search">
        <div className={style.search_wrapper}>
          <input type="hidden" name="searchid" defaultValue={config.YANDEX_SEARCHID} />
          <input
            type="text"
            name="text"
            placeholder="Крыло левое на BMW"
            className={style.search_input}
            autoComplete="off"
            ref={(c) => { this.searchInput = c; }}
            value={this.state.query}
            onFocus={this.activate}
            onChange={this.searchChange}
          />
          {/* <input type="hidden" name="type" value={carType} /> */}
          {this.state.active && !!this.state.suggest.length && (
            <div className={style.suggest} ref={(c) => { this.suggest = c; }}>
              {this.state.suggest.map((item, i) => (
                <div
                  key={item}
                  onClick={e => this.select(e, item)}
                  onMouseEnter={() => this.hover(i)}
                  className={classNames(style.suggest_item, i === this.state.selected && style.hover)}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={style.search_button_wrapper}>
          <Button name="searchbox" bold block filled>Найти</Button>
        </div>
      </form>
    );
  };
}

const mapStateToProps = state => ({
  carType: state.carType,
});
export default withRouter(connectAppConfig(connect(mapStateToProps)(SearchInput)));
