import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaskedInput from 'react-input-mask';
import style from './style';
import { PHONE_MASK } from 'src/constants';
import l10n from '../../../utils/l10n';

class Input extends Component {
  static propTypes = {
    name:        PropTypes.string,
    label:       PropTypes.string,
    placeholder: PropTypes.string,
    type:        PropTypes.string,
    errors:      PropTypes.arrayOf(PropTypes.string),
    mask:        PropTypes.string,
    value:       PropTypes.string,
    maxlength:   PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    validators:  PropTypes.arrayOf(PropTypes.func),
    onChange:    PropTypes.func,
  };

  static contextTypes = {
    form: PropTypes.any,
  };

  static defaultProps = {
    type:       'text',
    validators: [],
    onChange:   () => {},
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value:   this.props.value || '',
      touched: false,
      errors:  [],
    };
    this.change = this.change.bind(this);
    this.clear = this.clear.bind(this);
    this.complete = this.complete.bind(this);
    this.touch = this.touch.bind(this);
  }

  componentDidMount() {
    if (this.context.form) {
      this.context.form.addInput(this);
    }
    if (this.props.errors && this.props.errors.length) {
      this.updateErrors(this.props.errors);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors !== this.props.errors && Array.isArray(nextProps.errors)) {
      this.updateErrors(nextProps.errors);
    }
    if (nextProps.value !== null && nextProps.value !== undefined && nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  componentWillUnmount() {
    if (this.context.form) {
      this.context.form.removeInput(this);
    }
  }

  setErrors(errors) {
    let arErrors = null;
    if (errors) {
      if (!Array.isArray(errors)) {
        arErrors = [errors];
      } else {
        arErrors = errors;
      }
    }
    this.setState({ errors: arErrors });
  }

  clear() {
    this.setState({ value: '' });
  }

  complete(value) {
    this.setState({ value });
  }

  touch() {
    this.setState({ touched: true });
    this.validate();
  }

  validate() {
    const errors = this.props.validators.reduce((arr, validator) => {
      const res = validator(this.state.value);
      return res ? arr.concat(res) : arr;
    }, []);
    this.setState({ errors, touched: true });
    return errors;
  }

  change(e) {
    const { value } = e.target;
    this.setState({ value }, () => {
      if (this.state.touched) this.validate();
      this.props.onChange(this);
    });
  }

  updateErrors(errors) {
    this.setState({ errors, touched: true });
  }

  generateInput() {
    let { mask } = this.props;
    const { type, maxlength, name, placeholder } = this.props;

    if (type === 'phone' && !mask) {
      mask = PHONE_MASK;
    }

    const props = {
      onChange:       this.change,
      onBlur:         this.touch,
      className:      style.input,
      type,
      name,
      placeholder,
      maxLength:      maxlength,
      formNoValidate: true,
      noValidate:     true,
      value:          this.state.value,
    };

    if (mask) {
      props.mask = mask;
      return <MaskedInput {...props} />;
    }
    if (type === 'textarea') {
      props.className = classNames(style.input, style.textarea);
      return <textarea {...props} />;
    }
    return <input {...props} />;
  }

  render() {
    const { label } = this.props;
    const { errors } = this.state;

    return (
      <label className={style.wrapper}>
        {label ? <div className={style.label}>{label}</div> : null}
        {this.generateInput()}
        {errors
          ? (
            <div className={style.errors}>
              {errors.map(error => <div key={error} className={style.error}>{l10n(error)}</div>)}
            </div>
          )
          : null
        }
      </label>
    );
  }
}

export default Input;
